import React, { Fragment, useEffect } from "react";
import Home from "./components/Home/Home.js";
import About from "./components/About/About.js";
import Navbar from "./components/Navbar/Navbar.js";
import Contact from "./components/Contact/Contact.js";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Auth/Login/Login.js";
import Registration from "./components/Auth/Registration/Registration.js";
import Footer from "./components/Footer/Footer.js";
import ProductDetails from "./components/Home/ProductDetails/ProductDetails.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Product from "./components/Home/Product/Products.js";
import store from "./store.js";
import { loadUser } from "./actions/userActions.js";
import Profile from "./components/userProfile/Profile.js";
import UpdateProfile from "./components/User/UpdateProfile.js";
import UpdatePassword from "./components/User/UpdatePassword.js";
import ForgotPassword from "./components/User/ForgotPassword.js";
import ResetPassword from "./components/User/ResetPassword.js";
import Cart from "./components/Cart/Cart.js";
import ScrollToTop from "./ScrollToTop.js";
import ShippingInfo from "./components/Cart/ShippingInfo/ShippingInfo.js";
import ConfirmOrder from "./components/Cart/ConfirmOrder/ConfirmOrder.js";
import ProtectedRoutes from "./components/ProtectedRoute/ProtectedRoutes.js";
import Payment from "./components/Cart/Payment/Payment.js";
import PaymentSuccess from "./components/Cart/Payment/PaymentSuccess.js";
import PaymentCancel from "./components/Cart/Payment/PaymentCancel.js";
import MyOrders from "./components/Orders/MyOrders.js";
import "@stripe/react-stripe-js";
import OrderDetails from "./components/Orders/OrderDetails.js";
import Review from "./components/Orders/Reviews.js";
import Dashboard from "./components/Admin/Dashboard.js";
import Invoice from "./components/Admin/Invoice.js";
import AllUser from "./components/Admin/AllUser.js";
import AllOrders from "./components/Admin/AllOrders.js";
import UserProfile from "./components/userProfile/userProfile.js";
import CreateProduct from "./components/Admin/Product/CreateProduct.js";
import AllProducts from "./components/Admin/Product/AllProducts.js";
import WashingMachine from "./components/Home/WashingMachine.js";
import PrivacyPolicy from "./components/policy/Privacy-Policy.js";
import TermsConditions from "./components/policy/Terms-Conditions.js";
import CanclePolicy from "./components/policy/Cancle-Policy.js";
import Category from "./components/Admin/Category/Category.js";
import ProductCatgory from "./components/Home/ProductCategory/ProductCatgory.js";
import UpdateProduct from "./components/Admin/Product/updateProduct.js";
import PageNotFound from "./components/PageNoteFound/PageNoteFound.js";
import UserUpdate from "./components/Admin/UserUpdate.js";
import WishList from "./components/WishList/wishList.js";
import UpdateOrders from "./components/Admin/Product/UpdateOrders.js";
import Shipping from "./components/policy/Shipping.js";
import Blog from "./components/Blog/Blog.js";
import SubAdminDashboard from "./components/SubAdmin/SubAdminDashboard.js";
import CreateBlog from "./components/SubAdmin/CreateBlogs/CreateBlog.js";
import AllBlogs from "./components/SubAdmin/AllBlogs/allblogs.js";
import BlogDetails from "./components/SubAdmin/BlogDetails/BlogDetails.js";
import UploadImageDashboard from "./components/SubAdmin/UploadImage/UploadImageDashboard.js";
import UploadImage from "./components/SubAdmin/UploadImage/UploadImage.js";
import GetAllImage from "./components/SubAdmin/UploadImage/GetAllImage/GetAllImage.js";
import OrderDashborad from "./components/orderDashboard/orderDashboard.js";
import AllOrderDashboard from "./components/orderDashboard/AllOrderDashboard.js"
import OrderUpdate from "./components/orderDashboard/orderUpdate.js";
import ProductFilterByCategory from "./components/Home/ProductFilteredByCategory/ProductFilterByCategory.js";
import UpdateCategory from "./components/Admin/Category/UpdateCategory.js"
import Reviews from "./components/Admin/Reviews/Reviews.js"
import Brands from "./components/Admin/CreateBrands/CreateBrands.js"
import CreateBrand from "./components/Admin/CreateBrands/CreateBrand.js";
import AllBrands from "./components/Admin/CreateBrands/AllBrands/AllBrands.js";
import DeliveredOrder from "./components/Admin/DeliveredOrder/DeliveredOrder.js";

// import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.js";

const App = () => {
  // const { isAuthenticated } = useSelector((state) => state.user);

  // const [stripeApiKey, setStripeApiKey] = useState("");
  // // console.log(stripeApiKey);

  // async function getStripeApiKey() {
  //   try {
  //     const { data } = await axios.get("/api/v1/stripeapikey");
  //     setStripeApiKey(data.stripeApiKey);
  //     // console.log(data.stripeApiKey)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    // getStripeApiKey();

    store.dispatch(loadUser());
  }, []);
  return (
    <Fragment>
      {/* <Elements stripe={loadStripe(stripeApiKey)}> */}
        <Navbar />
        <ScrollToTop>
          {/* <About /> */}
          {/* {isAuthenticated && <UserProfile user={user} />} */}
          <Routes>
            <Route exect path="/" element={<Home />} />
            <Route path="/product/:slug/:id" element={<ProductDetails />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route
              path="/product-category/:slug"
              element={<ProductCatgory />}
            />
            <Route path="/category/:slug/:id" element={<ProductFilterByCategory />} />
            <Route path="/products/search" element={<Product />} />
            <Route path="/products/search/:keyword" element={<Product />} />
            <Route path="/washing-machine" element={<WashingMachine />} />
            <Route exect path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />

            <Route exect element={<ProtectedRoutes />}>
              <Route exect path="/profile" element={<Profile />} />
              <Route exect path="/wishlist" element={<WishList />} />
              <Route exect path="/user-profile" element={<UserProfile />} />
              <Route exect path="/me/update" element={<UpdateProfile />} />
              <Route exect path="/password/update" element={<UpdatePassword />} />
              <Route path="/shipping-details" element={<ShippingInfo />} />
              <Route exect path="/order/confirm" element={<ConfirmOrder />} />
              <Route exect path="/orders" element={<MyOrders />} />
              <Route exect path="/order/:id" element={<OrderDetails />} />
              <Route
                exect
                path="/order/:id/write-review"
                element={<Review />}
              />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route exect path="/process/payment" element={<Payment />} />
              <Route exect path="/success" element={<PaymentSuccess />} />
              <Route exect path="/cancel" element={<PaymentCancel />} />
            </Route>

            {/* Admin */}
            <Route element={<ProtectedRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/invoice" element={<Invoice />} />
              <Route path="/dashboard/alluser" element={<AllUser />} />
              <Route path="/dashboard/alluser/:id" element={<UserUpdate />} />
              <Route
                path="/dashboard/create-product"
                element={<CreateProduct />}
              />
              <Route path="/dashboard/all-products" element={<AllProducts />} />
              <Route
                path="/dashboard/all-products/:id"
                element={<UpdateProduct />}
              />
              <Route path="/dashboard/all-orders" element={<AllOrders />} />
              <Route
                path="/dashboard/all-orders/:id"
                element={<UpdateOrders />}
              />
              <Route path="/dashboard/category" element={<Category />} />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route
                path="/dashboard-subAdmin"
                element={<SubAdminDashboard />}
              />
              <Route
                path="/dashboard-subAdmin/create-blog"
                element={<CreateBlog />}
              />
              <Route
                path="/dashboard-subAdmin/allblogs"
                element={<AllBlogs />}
              />
              <Route
                path="/dashboard-subAdmin/upload-Images"
                element={<UploadImageDashboard />}
              />
              <Route
                path="/dashboard-subAdmin/upload-Images/upload"
                element={<UploadImage />}
              />
              <Route
                path="/dashboard-subAdmin/All-Image"
                element={<GetAllImage />}
              />

              <Route path="/dashboard/category/:slug/:id" element={<UpdateCategory />} />
              <Route path="/dashboard/allreviews" element={<Reviews />} />
              <Route path="/dashboard/brand" element={<Brands />} />
              <Route path="/dashboard/brand/create" element={<CreateBrand />} />
              <Route path="/dashboard/brand/allbrands" element={<AllBrands />} />
              <Route path="/dashboard/order/delivered" element={<DeliveredOrder />} />
              {/* <Route path="/" */}
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route path="/order-dashboard" element={<OrderDashborad />} />
              <Route path="/order-dashboard/allorder" element={<AllOrderDashboard />} />
              <Route path="/order-dashboard/allorder/:id" element={<OrderUpdate />} />
            </Route>

            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset/:token" element={<ResetPassword />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blogs/:title/:id" element={<BlogDetails />} />
            <Route
              path="/cancellations-return-policy"
              element={<CanclePolicy />}
            />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
          {/* </Router> */}
        </ScrollToTop>
        <Footer />
        <ToastContainer />
      {/* </Elements> */}
    </Fragment>
  );
};

export default App;
