import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserById, userRoleUpdate } from "../../actions/userActions";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";

const UserUpdate = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  // console.log(id);
 
  const { userDetails } = useSelector((state) => state.userRole);
// console.log(userDetails)
  const [name, setName] = useState();
  // console.log(name)
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [phone,setPhone] = useState()
  // console.log(role)

  const OnHandleUser = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("role", role);
    formData.append("phone",phone);

    dispatch(userRoleUpdate(id, formData));
    toast.success("user updated successfull");
  };

  useEffect(() => {
    if (userDetails && userDetails.name) {
      setName(userDetails.name);
    }
    if (userDetails && userDetails.email) {
      setEmail(userDetails.email);
    }
    if (userDetails && userDetails.phone) {
      setPhone(userDetails.phone);
    }

    if (userDetails && userDetails.role) {
      setRole(userDetails.role);
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id]);

  return (
    <div className="antialiased bg-lightgray w-full min-h-screen text-slate-300 relative py-4">
      <div className=" my-10 px-2 flex space-x-5">
        <div
          id="menu"
          className="bg-white col-span-3 rounded-lg p-4 sticky w-[300px]"
        >
          <Sidebar />
        </div>
        <div className="pt-10 pb-20 pl-10 pr-10 font-roboto bg-gray-light w-full rounded">
          {/* <h1 className='text-center font-bold text-lg '>User profile update</h1> */}
          <div className="w-full ">
            <div className="flex items-center ">
              <h1 className="w-2/6 font-semibold text-base">
                Account created Date
              </h1>
              <span>:</span>
              <h1 className="ml-2">{userDetails?.createdAt}</h1>
            </div>

            <div className="">
              <form action="" onSubmit={OnHandleUser}>
                <div className="flex items-center">
                  <h1 className="w-2/6 font-semibold text-base">Name</h1>
                  <span className="">:</span>
                  <input
                    className="ml-3 border-b w-3/6 h-10 bg-transparent"
                    type="text"
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  <h1 className="w-2/6 font-semibold text-base">Email</h1>
                  <span className="">:</span>
                  <input
                    className="ml-3 border-b w-3/6 h-10 bg-transparent"
                    type="text"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="flex items-center">
                  <h1 className="w-2/6 font-semibold text-base">Phone</h1>
                  <span className="">:</span>
                  <input
                    className="ml-3 border-b w-3/6 h-10 bg-transparent"
                    type="number"
                    value={phone}
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                <div className="flex items-center">
                  <h1 className="w-2/6 font-semibold text-base">User Role</h1>
                  <span className="">:</span>
                  <select
                    name="role"
                    id=""
                    className="border-b ml-3 font-medium text-base bg-transparent w-3/6 h-10"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value={role}>{role}</option>
                    <option value="admin">Admin</option>
                    <option value="subadmin">Subadmin</option>
                    <option value="orderdashboard">Orderdashboard</option>
                    <option value="user">User</option>
                  </select>
                </div>
                <button
                  type="submit"
                  className="pl-10 pr-10 pt-2 pb-2 bg-[#800000] text-white mt-5 rounded"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserUpdate;
